import React from 'react'
import './homepage.css'
import { Header } from '../header/Header'
import { Footer } from '../footer/Footer';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export function Homepage(props) { // Accept props as parameter
  return (
    <>
      <Header />
      <div className='homepage_Container'>
        <div className='homepage_Image'></div>
        <div className='homepage_Story'>
          <h1>"Op weg naar mijn hoogste doelen: Een rechte lijn naar succes!"</h1>
          <div className='homepage_Story-text'>Ik ben dat ene meisje in een wereld waar velen het ijsschaatsen omarmen, maar ik ben een laatbloeier. Met onzekerheid betreed ik het ijs, mijn schaatsen beladen met de last van een late start. Maar met elke gracieuze slag bewijs ik dat leeftijd slechts een getal is, geen beperking. Mijn doorzettingsvermogen kent geen grenzen; vastberaden werk ik mezelf omhoog naar de top. Mijn verhaal herinnert ons aan de kracht van wilskracht en de schoonheid van laat ontluikende dromen.</div>        </div>
      </div>
      <div className='homepage_Carousel'>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={5000}
          keyBoardControl={false}
          transitionDuration={2000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
          deviceType={props.deviceType}
          dotListClass="custom-dot-list-style"
        >
          <img className='homepage_CarouselImage' src="images/RC2_2302.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2303.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2305.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2306.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2307.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2338.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2345.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2346.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2349.jpg" alt="" />
          <img className='homepage_CarouselImage' src="images/RC2_2351.jpg" alt="" />
        </Carousel>
      </div>

      <div className='homepage_CurrentState'>
        <div className='homepage_CuttentState_content'>
          <div className='homepage_CurrentState_title'>Waar sta ik momenteel en wat zijn mijn plannen?</div>
          <div className='homepage_CurrentState_text'>
          Ik zit momenteel in de categorie Intermediate Girls B en streef ernaar om volgend competitie seizoen te eindigen in Junior A Women.
          Met vastberadenheid werk ik aan mijn doel: de sprong maken van Intermediate Girls B naar Advanced Girls B en dan naar Junior A Women. Ik wil mijn talent en passie laten
          zien op het hoogste niveau van het kunstschaatsen.
          </div>
        </div>
        <div className='homepage_CurrentState_image'></div>
      </div>
      
      <Footer />

    </>
  )
}
