import React from 'react'
import './header.css';

export function Header() {
    return (
        <>
            <div className='header_container'>
                <div className='header_name'></div>
            </div>
        </>
    )
}

