import logo from './logo.svg';
import './App.css';
import { Homepage } from './components/homepage/Homepage';

function App() {
  return (
    <>
     <Homepage />
    </>
  );
}

export default App;
