/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import './footer.css'

export function Footer() {
    return (
        <>
            <div className='homepage_sponsorFooter'>
                Website sponsored by:
                <a href='https://www.soletis.be' target='_blank' className='homepage_sponsorFooter-Soletis' rel="noreferrer"></a>
            </div>
        </>
    )
}

